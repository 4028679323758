<script>
import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";
import axios from 'axios';
import Swal from "sweetalert2";
import { QuillEditor } from "@vueup/vue-quill";
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import { ref, inject } from "vue";
import dayjs from "dayjs";

// import customParseFormat from 'dayjs/plugin/customParseFormat';
// dayjs.extend(customParseFormat)

export default {
    methods: {
        toggleFavourite(ele) {
            ele.target.closest('.favourite-btn').classList.toggle("active");
        },
        addToList(data) {
            var isAdd = this.contactAdd.findIndex(el => el === data.id) === -1 ? false : true;

            if (isAdd) {
                this.listPreview.push({
                    id: data.id,
                    photo: data.ava
                })
            } else {
                var idx = this.listPreview.findIndex(el => el.id === data.id);
                this.listPreview.splice(idx, 1);
            }
        },
        async saveLists() {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            if (this.titleList === '' || this.contactAdd.length <= 0) {
                Toast.fire({
                    icon: 'warning',
                    title: 'Please input title and min 1 customer to add'
                })
            } else {
                var formData = new FormData();
                formData.append("title", this.titleList);
                formData.append("CompanyID", JSON.parse(localStorage.getItem("user")).Company.ID);
                formData.append("customer", JSON.stringify(this.contactAdd));

                var param = "?ClientID=" + process.env.VUE_APP_CLIENT_ID + "&AccessToken=" + localStorage.getItem("jwt")
                if (this.isUpdate) {
                    const updateContact = await axios.post(process.env.VUE_APP_URL_API + "contact/update/" + this.idUpdate + param, formData);
                    const dataRes = updateContact.data;

                    if (dataRes.Meta.Code !== 200) {
                        Toast.fire({
                            icon: 'error',
                            title: dataRes.Meta.Message
                        })
                        return;
                    }

                    var idx = this.listsData.findIndex(el => el.ID === dataRes.Data.ID);
                    this.listsData.splice(idx, 1, dataRes.Data);

                    Toast.fire({
                        icon: 'success',
                        title: dataRes.Meta.Message
                    })
                } else {
                    const saveContact = await axios.post(process.env.VUE_APP_URL_API + "contact/save" + param, formData)
                    const dataRes = saveContact.data;
                    if (dataRes.Meta.Code !== 200) {
                        Toast.fire({
                            icon: 'error',
                            title: dataRes.Meta.Message
                        })
                        return;
                    }
                    this.listsData.push(saveContact.data.Data);
                    Toast.fire({
                        icon: 'success',
                        title: dataRes.Meta.Message
                    })
                }
                document.getElementById("closeModal").click();

            }
        },
        async edit(id) {
            this.isUpdate = true;
            this.idUpdate = id;
            this.listPreview = [];
            this.contactAdd = [];

            var param = "?ClientID=" + process.env.VUE_APP_CLIENT_ID + "&AccessToken=" + localStorage.getItem("jwt")
            const dataLoad = await axios.get(process.env.VUE_APP_URL_API + "contact/get/" + id + param)
            if (dataLoad.data.Meta.Code === 200) {
                this.titleList = dataLoad.data.Data.Title;
                const resData = dataLoad.data.Data.Customer;                
                if(resData !== null){
                    resData.map(item => {
                        this.listPreview.push({
                            id: item.ID,
                            nama: item.Nama,
                            photo: item.Avatar ? item.Avatar.Original : this.profile
                        })
                        this.contactAdd.push(item.ID);
                    })
                }
            }

            this.paginateListUsers();
        },
        removeLists(id) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Yes, delete it!",
            }).then(async (result) => {
                if (result.value) {
                    var idxList = this.listsData.findIndex(el => el.ID === id);
                    this.listsData.splice(idxList, 1);

                    var param = "?ClientID=" + process.env.VUE_APP_CLIENT_ID + "&AccessToken=" + localStorage.getItem("jwt")
                    await axios.get(process.env.VUE_APP_URL_API + "contact/delete/" + id + param)

                    Swal.fire("Deleted!", "Your file has been deleted.", "success");
                }
            });
        },
        loadHtml(delta) {
            this.dataContent = delta;
        },
        async uploadMedia() {
            var type = this.$refs.file.files[0].type.split("/")[0]
            if(type === 'video'){
                this.Toast.fire({
                    icon: 'error',
                    title: 'Video not valid on template'
                });
                document.getElementById("formFileSm").value = ''
                return;
                /*  var url = URL.createObjectURL(this.formMedia)
                var thumb = await this.getThumbnailForVideo(url)
                this.prevImg = thumb */
            } else {
                this.formMedia = this.$refs.file.files[0]
                var base64 = await this.toBase64(this.$refs.file.files[0])
                this.prevImg = base64;
            }
        },
        mediaRemove() {
            if (!this.isMedia) {
                this.prevImg = this.defaultImgPrev
            }
        },
        addVariable() {
            this.listVariable.push({
                variable: ''
            })
        },
        async changeVar(i) {
            var varEL = document.getElementById("var_id_" + i)
            var rowEl = document.getElementById("rowVar_" + i) // for div row
            var rowDate = document.getElementById("rowDate_" + i) // for div row date
            var rowAction = document.getElementById("action_"+ i)

            var message;
            if (varEL.value === 'tgl') {
                rowEl.classList.remove("col-11")
                rowEl.classList.add("col-6")
                rowDate.classList.add("col-5")
                rowAction.classList.add("col-1")
                rowDate.style.display = "";

                var datePick = document.getElementById("datePick_"+i)
                this.listVariable[i] = {
                    variable: `tgl${i}`,
                    value: datePick.value 
                };

                if(!datePick.value){
                    message = this.content.replace("</p>", ` {{ tgl${i} }} </p>`);
                    this.content = message;
                }
            } else {
                rowEl.classList.remove("col-6")
                rowDate.classList.remove("col-5")
                if(i > 0){
                    rowAction.classList.add("col-1")
                }
                this.listVariable[i] = {
                    variable: varEL.value
                }
                rowDate.style.display = "none";
                message = this.content.replace("</p>", ` {{ ${varEL.value} }} </p>`);
                this.content = message;
            }

            
            this.dataContent = this.content
        },
        addTemplate() {
            this.isUpdate = false;
            this.idUpdate = 0;
            this.dataContent = "<i>preview</i>";
            this.content = "";
            this.$refs.quilEditor.setContents(this.content)
            this.titleTemplate = "";
            this.listVariable = [];
            this.isMedia = false;
            this.formMedia = null;
        },
        async saveTemplate() {

            if (this.titleTemplate === '' || this.dataContent === '') {
                this.Toast.fire({
                    icon: 'error',
                    title: "Field title & content required"
                })
                return;
            }

            const formData = new FormData();
            formData.append("Title", this.titleTemplate)
            formData.append("Content", this.dataContent.trim())
            formData.append("Variable", JSON.stringify(this.listVariable))
            formData.append("kodemb", JSON.parse(localStorage.getItem("user")).Company.CompanyCode)

            if (this.isMedia && this.formMedia !== null) {
                formData.append("Gambar", this.formMedia)
            }

            var param = "?ClientID=" + process.env.VUE_APP_CLIENT_ID + "&AccessToken=" + localStorage.getItem("jwt")

            if (this.idUpdate !== 0) {
                param += "&TemplateID=" + this.idUpdate;
            }

            var url = process.env.VUE_APP_URL_API + "broadcast/saveTemplate" + param;
            const saveData = await axios.post(url, formData)
            if (saveData.data.Meta.Code === 200) {
                
                await this.getTemplate();

                this.Toast.fire({
                    icon: 'success',
                    title: 'Template saved'
                })
                document.getElementById("closeModalAddTemplate").click();
                return;
            }
        },
        async getHotProp(param) {
            const hot_prop = await axios.get("http://xaviermarks.com/property-api/search/" + param);
            return hot_prop.data;
        },
        async getTemplate() {
            this.templateData = [];
            var addExtraParams = "?ClientID=" + process.env.VUE_APP_CLIENT_ID + "&AccessToken="
                + localStorage.getItem("jwt") + "&kodemb=" + JSON.parse(localStorage.getItem("user")).Company.CompanyCode;

            const listTemplate = await axios.get(process.env.VUE_APP_URL_API + "/broadcast/getTemplate" + addExtraParams);
            const res = listTemplate.data;
            if (res.Meta.Code !== 200) {
                this.Toast.fire({
                    icon: 'error',
                    title: res.Meta.Message
                });
                return;
            }
            res.Data.forEach(item => {
                this.templateData.push(item);
            });
        },
        async editTempalte(id) {
            var addExtraParams = "?ClientID=" + process.env.VUE_APP_CLIENT_ID + "&AccessToken="
                + localStorage.getItem("jwt") + "&kodemb="
                + JSON.parse(localStorage.getItem("user")).Company.CompanyCode
                + "&TemplateID=" + id;

            this.isUpdate = true;
            this.idUpdate = id;

            // Run API FOR Get emplate by id
            const listTemplate = await axios.get(process.env.VUE_APP_URL_API + "/broadcast/getTemplate" + addExtraParams);
            const res = listTemplate.data;

            if (res.Data.Gambar !== null) {
                this.isMedia = true;
                this.prevImg = res.Data.Gambar
            } else {
                this.isMedia = false;
                this.prevImg = null
            }

            this.titleTemplate = res.Data.Title;
            this.content = res.Data.Content;

            this.dataContent = this.content;

            this.listVariable = [];
            const variableContent = JSON.parse(res.Data.Variable) 
            if(variableContent.length !== 0){
                this.listVariable = variableContent
                setTimeout(() => {
                    for (let _idx = 0; _idx < variableContent.length; _idx++) {
                        const element = variableContent[_idx];
    
                        const domEl = document.getElementById("var_id_"+_idx);
                        var rowAction = document.getElementById("action_"+ _idx)
                        var rowDate = document.getElementById("rowDate_" + _idx)
                        var rowVar = document.getElementById("rowVar_" + _idx)
    
                        if(element.variable === `tgl${_idx}`){
                            domEl.value = 'tgl'
                            rowVar.classList.remove("col-11")
                            rowVar.classList.add("col-6")
                            rowDate.classList.add("col-5")
                            rowDate.style.display = "";
                            rowAction.classList.add("col-1")
    
                            var datePick = document.getElementById("datePick_"+_idx)
                            datePick.value = element.value
                        } else {
                            domEl.value = element.variable
                        }
                    }
                }, 500)
            }
        },
        async sendBC() {
            if(this.idList === 0 || this.idTemplate === 0 || this.TitleCampaign === ''){
                this.Toast.fire({
                    icon: 'error',
                    title: 'All Fields is required'
                })
                return;
            }

            var param = "?ClientID=" + process.env.VUE_APP_CLIENT_ID + "&AccessToken=" + localStorage.getItem("jwt");
            const idxList = this.listsData.findIndex(e => e.ID === this.idList);
            const idxTemplate = this.templateData.findIndex(e => e.ID === this.idTemplate);
            const list = this.listsData[idxList];
            const template = this.templateData[idxTemplate]

            let formData = null

            document.getElementById("closeModalAddCampain").click();

            const agent = JSON.parse(localStorage.getItem("userdata"));
            var agentParams = {
                ID: agent.ID,
                Name: agent.Name,
                Role: agent.Role,
                Avatar: agent.Avatar
            }

            if(this.idUpdate === 0){
                var countDelivered = 0;
                for (let _idx = 0; _idx < list.Customer.length; _idx++) {
                    const row = list.Customer[_idx];
                    var messageSend = template.Content.replace("{{ name }}", row.Nama);
    
                    var varValue = JSON.parse(template.Variable)
                    for (let idx_var = 0; idx_var < varValue.length; idx_var++) {
                        const element = varValue[idx_var];
                        if(element.variable !== 'name'){
                            if(messageSend.includes(`{{ tgl${idx_var} }}`)){
                                messageSend = messageSend.replace(`{{ tgl${idx_var} }}`, element.value);
                            }
                        }
                    }
    
                    var regexSEND = /(<([^>]+)>)/ig
                    messageSend = messageSend.replace("<strong>","*")
                        .replace("</strong>","*")
                        .replace("<em>","_")
                        .replace("</em>","_")
                        .replace("<s>","~")
                        .replace("</s>","~")
                        .replace(regexSEND, "");
    
                    if (template.Gambar === null) {
                        formData = {
                            kodemb: JSON.parse(localStorage.getItem("userdata")).Company.CompanyCode,
                            phone: row.Phone,
                            message: messageSend,
                            agent: JSON.stringify(agentParams)
                        }
                    } else {
                        formData = new FormData();
                        formData.append("phone", row.Phone)
                        formData.append("type", "MEDIA")
                        formData.append("kodemb", JSON.parse(localStorage.getItem("userdata")).Company.CompanyCode)
                        formData.append("media_source", "URL")
                        formData.append("message", messageSend)
                        formData.append("image_url", template.Gambar)
                        formData.append("agent", JSON.stringify(agentParams))
                    }
    
                    const messRess = await axios.post(process.env.VUE_APP_WA_BASE_URL + "api/whatsapp/send", formData, {
                        headers: {
                            'content-type': 'application/json; charset=utf-8',
                            clientid: 'abcd1234',
                            authorization: JSON.parse(localStorage.getItem("userdata")).Company.CompanyCode
                        }
                    })
    
                    if(messRess.data.status === 500){
                        this.Toast.fire({
                            icon: 'error',
                            title: messRess.data.message
                        });
                        return;
                    }
    
                    var dataPost = new FormData();
                    dataPost.append("AgentID", agent.ID);
                    dataPost.append("MessageID", messRess.data.data.id.id);
                    dataPost.append("CustomerID", row.ID);
    
                    if (messRess.data.status === 200) {
                        await axios.post(process.env.VUE_APP_URL_API + "agent-api/saveChat" + param, dataPost)
                    }
                    countDelivered ++;
                }
    
                var url = process.env.VUE_APP_URL_API + "broadcast/saveCampain" + param + "&id="+this.idUpdate
                var fromDataParam = new FormData();
                fromDataParam.append("CompanyID", JSON.parse(localStorage.getItem("user")).Company.ID);
                fromDataParam.append("ContactListID", list.ID);
                fromDataParam.append("TemplateID", template.ID);
                fromDataParam.append("Title", this.TitleCampaign);
                fromDataParam.append("Delivered", countDelivered);
    
                const savedCampain = await axios.post(url, fromDataParam);
                const resSaved = savedCampain.data;
                if(resSaved.Meta.Code !== 200){
                    this.Toast.fire({
                        icon: 'error',
                        title: resSaved.Meta.Message
                    })    
                }

                this.campainData.push(resSaved.Data)
                this.Toast.fire({
                    icon: 'success',
                    title: 'Message sent'
                })
                this.idUpdate = 0
            } else {
                var updateurl = process.env.VUE_APP_URL_API + "broadcast/saveCampain" + param + "&id="+this.idUpdate
                var fromDataParamUpdate = new FormData();
                fromDataParamUpdate.append("ContactListID", list.ID);
                fromDataParamUpdate.append("TemplateID", template.ID);
                fromDataParamUpdate.append("Title", this.TitleCampaign);
    
                const savedCampain = await axios.post(updateurl, fromDataParamUpdate);
                const resSaved = savedCampain.data;
                if(resSaved.Meta.Code !== 200){
                    this.Toast.fire({
                        icon: 'error',
                        title: resSaved.Meta.Message
                    })
                } else {
                    this.Toast.fire({
                        icon: 'success',
                        title: resSaved.Meta.Message
                    })

                    const indexs = this.campainData.findIndex(item => item.ID === resSaved.Data.ID)

                    if(indexs !== -2){
                        this.campainData[indexs] = resSaved.Data
                    }
                }
            }
        },
        async getCampain() {
            this.campainData = [];
            var param = "?ClientID=" + process.env.VUE_APP_CLIENT_ID + "&AccessToken=" + localStorage.getItem("jwt");
            var extraparam = param + "&kodemb=" + JSON.parse(localStorage.getItem("user")).Company.CompanyCode
            var url = process.env.VUE_APP_URL_API + "broadcast/getCampain" + extraparam
            const campain = await axios.get(url);
            const resCampain = campain.data;

            if (resCampain.Meta.Code !== 200) {
                this.Toast.fire({
                    icon: "error",
                    title: resCampain.Meta.Message
                })
            }

            if(resCampain.Data){
                resCampain.Data.forEach(element => {
                    if(!element.ContactList.Customer){
                        element.ContactList.Customer = [];
                    }
                    this.campainData.push(element);
                });
            }
        },
        async sendCampain(id) {
            if(this.whatsappStatus){
                var btnSend = document.getElementById("btnSendCampain-"+id)
                btnSend.innerHTML = "Sending..."
                btnSend.setAttribute("disabled","disabled")
    
                var param = "?ClientID=" + process.env.VUE_APP_CLIENT_ID + "&AccessToken=" + localStorage.getItem("jwt");
                var extraparam = param + "&kodemb=" + JSON.parse(localStorage.getItem("user")).Company.CompanyCode + "&CampainID=" + id
                var url = process.env.VUE_APP_URL_API + "broadcast/getCampain" + extraparam
                const campain = await axios.get(url);
                const resCampain = campain.data;
    
                if (resCampain.Meta.Code !== 200) {
                    this.Toast.fire({
                        icon: "error",
                        title: resCampain.Meta.Message
                    })
                    return;
                }
    
                const agent = JSON.parse(localStorage.getItem("userdata"));
                let formData = null
                let list = resCampain.Data.ContactList
                const template = resCampain.Data.Template
    
                var agentParams = {
                    ID: agent.ID,
                    Name: agent.Name,
                    Role: agent.Role,
                    Avatar: agent.Avatar
                }
    
                var deliveredCount = 0;
                for (let _idx = 0; _idx < list.Customer.length; _idx++) {
                    const row = list.Customer[_idx];
                    var message = template.Content.replace("{{ name }}", row.Nama);
    
                    var varValue = JSON.parse(template.Variable)
                    for (let idx_var = 0; idx_var < varValue.length; idx_var++) {
                        const element = varValue[idx_var];
                        if(element.variable !== 'name'){
                            if(message.includes(`{{ tgl${idx_var} }}`)){
                                let formatedDate = dayjs(element.value).format("DD MMM YYYY");
                                message = message.replace(`{{ tgl${idx_var} }}`, formatedDate);
                            }
                        }
                    }
                    
                    var regex = /(<([^>]+)>)/ig
                    message = message.replace("<strong>","*")
                        .replace("</strong>","*")
                        .replace("<em>","_")
                        .replace("</em>","_")
                        .replace("<s>","~")
                        .replace("</s>","~")
                        .replaceAll("</p>", "\n")
                        .replace(regex, "");
    
                    if (template.Gambar === null) {
                        formData = {
                            kodemb: JSON.parse(localStorage.getItem("userdata")).Company.CompanyCode,
                            phone: row.Phone,
                            message: message,
                            agent: JSON.stringify(agentParams)
                        }
                    } else {
                        formData = new FormData();
                        formData.append("phone", row.Phone)
                        formData.append("type", "MEDIA")
                        formData.append("kodemb", JSON.parse(localStorage.getItem("userdata")).Company.CompanyCode)
                        formData.append("media_source", "URL")
                        formData.append("message", message)
                        formData.append("image_url", template.Gambar)
                        formData.append("agent", JSON.stringify(agentParams))
                    }
    
                    const messRess = await axios.post(process.env.VUE_APP_WA_BASE_URL + "api/whatsapp/send", formData, {
                        headers: {
                            'content-type': 'application/json; charset=utf-8',
                            clientid: 'abcd1234',
                            authorization: JSON.parse(localStorage.getItem("userdata")).Company.CompanyCode
                        }
                    })
    
                    if(messRess.data.status === 500){
                        this.Toast.fire({
                            icon: 'error',
                            title: messRess.data.message
                        });
                        return;
                    }
    
                    var dataPost = new FormData();
                    dataPost.append("AgentID", agent.ID);
                    dataPost.append("MessageID", messRess.data.data.id.id);
                    dataPost.append("CustomerID", row.ID);
    
                    if (messRess.data.status === 200) {
                        await axios.post(process.env.VUE_APP_URL_API + "agent-api/saveChat" + param, dataPost)
                    }
                    deliveredCount ++;
                }
    
                var urlUpdateDelivered = process.env.VUE_APP_URL_API + "broadcast/saveCampain" + param + "&id="+id
                var fromDataParam = new FormData();
                fromDataParam.append("Delivered", deliveredCount);
                const updateDelivered = await axios.post(urlUpdateDelivered, fromDataParam);
                
                if(updateDelivered.status !== 200){
                    this.Toast.fire({
                        icon: 'error',
                        title: updateDelivered.statusText
                    })
                    return;
                }
    
                const res = updateDelivered.data;
                var idxCampain = this.campainData.findIndex(el => el.ID === id);
                this.campainData[idxCampain] = res.Data
    
                this.Toast.fire({
                    icon: 'success',
                    title: 'Message sent'
                })
    
                btnSend.innerHTML = "Send Campain"
                btnSend.removeAttribute("disabled")
            } else {
                this.Toast.fire({
                    icon: "warning",
                    title: "WhatsApp not connected"
                })
            }
        },
        deleteTemplate(id) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                cancelButtonColor: "#f46a6a",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "Yes, delete it!",
            }).then(async (result) => {
                if (result.value) {
                    var param = "?ClientID=" + process.env.VUE_APP_CLIENT_ID + "&AccessToken=" + localStorage.getItem("jwt");
                    var extraparam = param + "&TemplateID=" + id
                    var url = process.env.VUE_APP_URL_API + "/broadcast/deleteTemplate" + extraparam
                    const templateDel = await axios.get(url)
                    const res = templateDel.data;

                    if (res.Meta.Code !== 200) {
                        this.Toast.fire({
                            icon: 'error',
                            title: "Delete fail"
                        })
                    }

                    this.Toast.fire({
                        icon: 'success',
                        title: "Delete success"
                    })
                    var idxTemplate = this.templateData.findIndex(el => el.ID === id);
                    this.templateData.splice(idxTemplate, 1)
                }

            });

        },
        async editCampain(id)
        {
            var idxCmp = this.campainData.findIndex(el => el.ID === id);
            this.idList = this.campainData[idxCmp].ContactList.ID
            this.idTemplate = this.campainData[idxCmp].Template.ID
            this.TitleCampaign = this.campainData[idxCmp].Title
            this.idUpdate = id;

            await this.getTemplate();
        },
        removeVar(idx){
            this.listVariable.splice(idx, 1)
            var varNya = "{{ "+this.listVariable[idx].variable+" }}";
            var setContent = this.dataContent.replace(varNya, "");
            this.content = setContent
            this.dataContent = setContent
        },
        addCampain(){
            this.idUpdate = 0
            this.idList = []
            this.idTemplate = []
            this.TitleCampaign = ''
        },
        /* 
        * function indexDB
        */
        getAllContact(){
            return new Promise((resolve, reject) => {
                let trans = this.db.transaction(['customer'], 'readonly');
                trans.oncomplete = () => {
                    console.log("Success get customer idb");
                    resolve(customer);
                }

                trans.onerror = e => {
                    console.log("error get customer idb");
                    reject(e)
                }

                let store = trans.objectStore("customer");
                let objStoreReq = store.getAll();
                let customer = [];

                objStoreReq.onsuccess = () => {
                    objStoreReq.result.sort((a, b) => dayjs(b.TimestampLastChat).unix() - dayjs(a.TimestampLastChat).unix());
                    customer = objStoreReq.result
                }

                objStoreReq.onerror = () => {
                    console.log("Error get customer");
                }
            })
        },
        addCustomerToDB(cust){
            return new Promise((resolve, reject) => {
                let trans = this.db.transaction(['customer'], 'readwrite')
                trans.oncomplete = () => {
                console.log("Success add customer idb");
                resolve()
                }

                trans.onerror = e => {
                console.log("Error add customer idb");
                reject(e);
                }

                let store = trans.objectStore('customer')
                cust.forEach(item => {
                item.id = item.ID
                store.add(item)
                })
            })
        },
        cleanDataDB()
        {
            return new Promise((resolve, reject) => {
                let trans = this.db.transaction(['customer'], 'readwrite')
                let objectStore = trans.objectStore("customer");

                let clearRequest = objectStore.clear();
                clearRequest.onsuccess = () => {
                console.log("Success clean idb");
                resolve();
                }

                clearRequest.onerror = () => {
                console.log("Error clean idb");
                reject();
                }
            })
        },
        /**
        * for ppaginate list uses
        */
        paginateListUsers()
        {
            console.log("masuk load more list user");
            let bodyScroll = this.$refs.contentScroll
            bodyScroll.addEventListener("scroll", () => {
                let scrollPosition = (parseInt(bodyScroll.scrollTop) + 1) + parseInt(bodyScroll.clientHeight);
                let contentheight = bodyScroll.scrollHeight
                if(scrollPosition >= contentheight){
                    console.log("load more list users");
                    setTimeout(() => {
                        axios.get(`${process.env.VUE_APP_URL_API}/customer-api/getCustomerData`, {
                            params: {
                                ClientID: process.env.VUE_APP_CLIENT_ID,
                                AccessToken: localStorage.getItem("jwt"),
                                status: 'all',
                                Start: this.listCustomer.length,
                                Count: 10
                            }
                        }).then(res => {
                            if("Meta" in res.data){
                                let response = res.data;
                                if(response.Meta.Code == 200){
                                    response.Data.forEach(item => {
                                        this.listCustomer.push({
                                            id: item.ID,
                                            name: item.Name,
                                            phone: item.Phone,
                                            ava: item.Avatar ? item.Avatar.Original : this.profile
                                        })
                                    });
                                }
                            }
                        }).catch(err => {
                            console.log(err);
                        })
                    }, 500);
                }
            })
        }
    /* 
     * end
     */
    },
    page: {
        title: "Broadcast",
        meta: [{ name: "description", content: appConfig.description }],
    },
    setup() {
        const content = ref("")

        const toBase64 = file => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })

        const getThumbnailForVideo = async (videoUrl) => {
            const video = document.createElement("video");
            const canvas = document.createElement("canvas");
            video.style.display = "none";
            video.style.background = "url('https://icons.veryicon.com/png/o/miscellaneous/food-time/play-video-1.png') no-repeat center"
            canvas.style.display = "none";

            // Trigger video load
            await new Promise((resolve) => {
                video.addEventListener("loadedmetadata", () => {
                    video.width = video.videoWidth;
                    video.height = video.videoHeight;
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;
                    // Seek the video to 25%
                    video.currentTime = video.duration * 0.25;
                });
                video.addEventListener("seeked", () => resolve());
                video.src = videoUrl;
            }).catch(err => console.log(err));

            // Draw the thumbnailz
            canvas
                .getContext("2d")
                .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            const imageUrl = canvas.toDataURL("image/png");
            return imageUrl;
        }

        return { content, toBase64, Toast, getThumbnailForVideo }
    },
    data() {
        return {
            title: "Broadcast",
            items: [
                {
                    text: "Pages",
                    href: "/",
                },
                {
                    text: "Broadcast",
                    active: true,
                },
            ],
            company: JSON.parse(localStorage.getItem("user")).Company.Name,
            ExpDate: JSON.parse(localStorage.getItem("user")).Company.ExpDate,
            profile: require('@/assets/images/users/user-dummy-img.jpg'),
            defaultImgPrev: 'https://flxtable.com/wp-content/plugins/pl-platform/engine/ui/images/image-preview.png',
            prevImg: null,
            listCustomer: [],
            contactAdd: [],
            listPreview: [],
            titleList: '',
            showAlert: false,
            listsData: [], // for list data
            templateData: [], // for template data
            campainData: [],
            isUpdate: false,
            idUpdate: 0,
            dataContent: "<i>Preview</i>",
            isMedia: false,
            formMedia: null,
            listVariable: [],
            dataListVariable: [
                { var: "name", title: "Name" },
                { var: "tgl", title: "Tanggal" }
            ],
            timestamp: "",
            titleTemplate: "",
            idList: 0,
            idTemplate: 0,
            TitleCampaign: '',
            showShimmer: false,
            whatsappStatus: false,
            searchQuery: "",
            db: inject("db")
        };
    },
    components: {
        Layout,
        QuillEditor
    },
    async beforeMount() {
        this.showShimmer = true;
        this.listCustomer = [];

        var param = "?ClientID=" + process.env.VUE_APP_CLIENT_ID + "&AccessToken=" + localStorage.getItem("jwt")
        const dataLists = await axios.get(process.env.VUE_APP_URL_API + "contact/get" + param +"&kodemb="+JSON.parse(localStorage.getItem("user")).Company.CompanyCode)

        const resDataLists = dataLists.data;
        
        if (resDataLists.Meta.Code === 200) {
            resDataLists.Data.forEach(element => {
                if(!element.Customer){
                    element.Customer = [];
                }
                this.listsData.push(element);
            });
            this.showShimmer = false;
        }

        let contactLocal = JSON.stringify({
            data: {
                Data:  await this.getAllContact()
            }
        });
        
        let resData = [];
        if(contactLocal === null){
            const customerData = await axios.get(process.env.VUE_APP_URL_API + "customer-api/getCustomerData", {
                params: {
                    AccessToken: localStorage.getItem("jwt"),
                    ClientID: process.env.VUE_APP_CLIENT_ID,
                    status: 'all'
                }
            });
            resData = customerData.data.Data;
        } else {
            resData = JSON.parse(contactLocal).data.Data;
            resData.map(item => {
                this.listCustomer.push({
                    id: item.ID,
                    name: item.Name,
                    phone: item.Phone,
                    ava: item.Avatar ? item.Avatar.Original : this.profile
                });
            })
        }

        this.prevImg = this.defaultImgPrev
        this.getTemplate();
        this.getCampain();
        this.showShimmer = false;
    },
    created() {
        const today = new Date();
        var hour = today.getHours() < 10 ? "0" + today.getHours() : today.getHours();
        var minutes = today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes();
        const time = hour + ":" + minutes;
        this.timestamp = time;
    },
    async mounted() {
        let kodemb = JSON.parse(localStorage.getItem("user")).Company.CompanyCode
        let urlWaStatus = process.env.VUE_APP_WA_BASE_URL+"api/whatsapp/status?kodemb="+kodemb
        const reqWaStatus = await axios.get(urlWaStatus, {
            headers: {
                clientid: "abcd1234"
            }
        })
        const resData = reqWaStatus.data
        if(resData.data === 'WAITING'){
            this.whatsappStatus = false;
        } else {
            this.whatsappStatus = true;
        }
    },
    computed: {
        showUserContact(){
            return this.listPreview
        }
    }
};
</script>

<style>
#previewBody {
    margin: 0;
    font-family: Arial, Helvetica, sans-serif;
    background: url('http://forums.crackberry.com/attachments/blackberry-10-wallpapers-f308/137432d1361639896t-z10-wallpaper-set-z10-music.jpg');
}

.speech-wrapper {
    padding: 30px 40px;
}

.speech-wrapper .bubble {
    width: 240px;
    height: auto;
    display: block;
    background: #f5f5f5;
    border-radius: 4px;
    box-shadow: 2px 8px 5px #000;
    position: relative;
    margin: 0 0 25px;
}

.speech-wrapper .bubble.alt {
    margin: 0 0 0 60px;
}

.speech-wrapper .bubble .txt {
    padding: 8px 14px 8px 14px;
}

.speech-wrapper .bubble .txt .name {
    font-weight: 600;
    font-size: 12px;
    margin: 0 0 4px;
    color: #3498db;
}

.speech-wrapper .bubble .txt .name span {
    font-weight: normal;
    color: #b3b3b3;
}

.speech-wrapper .bubble .txt .name.alt {
    color: #2ecc71;
}

.speech-wrapper .bubble .txt .message {
    font-size: 12px;
    margin: 0;
    color: #2b2b2b;
    text-align: justify;
    text-justify: inter-word;
}

.speech-wrapper .bubble .txt .timestamp {
    font-size: 11px;
    position: absolute;
    bottom: 3px;
    right: 10px;
    text-transform: uppercase;
    color: #999;
}

.speech-wrapper .bubble .bubble-arrow {
    position: absolute;
    width: 0;
    bottom: 42px;
    left: -16px;
    height: 0;
}

.speech-wrapper .bubble .bubble-arrow.alt {
    right: -2px;
    bottom: 40px;
    left: auto;
}

.speech-wrapper .bubble .bubble-arrow:after {
    content: "";
    position: absolute;
    border: 0 solid transparent;
    border-top: 9px solid #f5f5f5;
    border-radius: 0 20px 0;
    width: 15px;
    height: 30px;
    transform: rotate(145deg);
}

.speech-wrapper .bubble .bubble-arrow.alt:after {
    transform: rotate(45deg) scaleY(-1);
}
</style>

<template>
    <Layout>
        <div class="row">
            <div class="col-lg-12">
                <div class="card mt-n4 mx-n4">
                    <div class="bg-soft-warning">
                        <div class="card-body pb-0 px-4">
                            <div class="row mb-3">
                                <div class="col-md">
                                    <div class="row align-items-center g-3">
                                        <div class="col-md-auto">
                                            <div class="avatar-md">
                                                <div class="avatar-title bg-white rounded-circle">
                                                    <img src="@/assets/images/brands/slack.png" alt=""
                                                        class="avatar-xs">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md">
                                            <div>
                                                <h4 class="fw-bold">Broadcast</h4>
                                                <div class="hstack gap-3 flex-wrap">
                                                    <div><i class="ri-building-line align-bottom me-1"></i> {{
                                                        company
                                                    }}</div>
                                                    <div class="vr"></div>
                                                    <div>Exp Date : <span class="fw-medium">{{ ExpDate }}</span></div>
                                                    <div class="vr"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <ul class="nav nav-tabs-custom border-bottom-0" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active fw-semibold" data-bs-toggle="tab" href="#listCampgain"
                                        role="tab">
                                        List
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link fw-semibold" @click="getTemplate" data-bs-toggle="tab"
                                        href="#project-documents" role="tab">
                                        Templates
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link fw-semibold" data-bs-toggle="tab" href="#project-activities"
                                        role="tab" @click="this.getCampain">
                                        Campaign
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <!-- end card body -->
                    </div>
                </div>
                <!-- end card -->
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
        <div class="row">
            <div class="col-lg-12">
                <div class="tab-content text-muted">
                    <!-- Tab template -->
                    <div class="tab-pane fade" id="project-documents" role="tabpanel">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex align-items-center mb-4">
                                    <h5 class="card-title flex-grow-1">Templates</h5>
                                    <button type="button" id="btnAddTemplate" class="btn btn-success"
                                        data-bs-toggle="modal" data-bs-target="#addTemplates" data-backdrop="static" @click="addTemplate">
                                        <span class="icon-on"><i class="ri-add-line align-bottom me-1"></i> Add
                                            Templates</span>
                                    </button>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="table-responsive table-card">
                                            <table class="table table-borderless align-middle mb-0">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th scope="col">Image Thumnail</th>
                                                        <th scope="col">Title</th>
                                                        <th scope="col" style="width: 120px;">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="template of templateData" :key="template.ID">
                                                        <td>
                                                            <div class="d-flex align-items-center">
                                                                <div class="avatar-sm">
                                                                    <div v-if="!template.Gambar" class="avatar-title bg-light text-secondary rounded fs-24 shadow">
                                                                        <i class="ri-image-2-line"></i>
                                                                    </div>
                                                                    <div v-else class="avatar-title bg-light text-secondary rounded fs-24 shadow">
                                                                        <img :src="template.Gambar" style="max-width: 80px; max-height: 80px;" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{{ template.Title }}</td>
                                                        <td>
                                                            <div class="col sm-6 mb-2">
                                                                <a class="btn btn-warning btn-sm" href="javascript:void(0);" data-bs-toggle="modal" data-bs-target="#addTemplates" data-backdrop="static"
                                                                    @click="this.isUpdate = true; editTempalte(template.ID);">
                                                                    <i class="ri-pencil-line me-2 align-bottom text-white"></i>Edit
                                                                </a>
                                                            </div>
                                                            <div class="col sm-6">
                                                                <a class="btn btn-danger btn-sm" @click="deleteTemplate(template.ID)" href="javascript:void(0);">
                                                                    <i class="ri-delete-bin-5-fill me-2 align-bottom text-white"></i>Delete
                                                                </a>
                                                            </div>
                                                            <!-- <div class="dropdown">
                                                                <a href="javascript:void(0);"
                                                                    class="btn btn-soft-secondary btn-sm btn-icon shadow-none"
                                                                    data-bs-toggle="dropdown" aria-expanded="true">
                                                                    <i class="ri-more-fill"></i>
                                                                </a>
                                                                <ul class="dropdown-menu dropdown-menu-end">
                                                                    <li><a class="dropdown-item"
                                                                            href="javascript:void(0);"
                                                                            data-bs-toggle="modal"
                                                                            data-bs-target="#addTemplates"
                                                                            data-backdrop="static"
                                                                            @click="this.isUpdate = true; editTempalte(template.ID);"><i
                                                                                class="ri-pencil-line me-2 align-bottom text-muted"></i>Edit</a>
                                                                    </li>
                                                                    <li><a class="dropdown-item"
                                                                            @click="deleteTemplate(template.ID)"
                                                                            href="javascript:void(0);"><i
                                                                                class="ri-delete-bin-5-fill me-2 align-bottom text-muted"></i>Delete</a>
                                                                    </li>
                                                                </ul>
                                                            </div> -->
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center mt-3" style="display: none;">
                                            <a href="javascript:void(0);" class="text-success "><i
                                                    class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i> Load
                                                more </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end tab pane -->
                    <!-- Tab campain -->
                    <div class="tab-pane fade" id="project-activities" role="tabpanel">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex align-items-center mb-4">
                                    <h5 class="card-title flex-grow-1">Campain</h5>
                                    <button type="button" id="btnAddCampaon" class="btn btn-success"
                                        data-bs-toggle="modal" data-bs-target="#campainModal" @click="addCampain">
                                        <span class="icon-on"><i class="ri-add-line align-bottom me-1"></i> Add
                                            Campain</span>
                                    </button>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="table-responsive table-card">
                                            <table class="table table-borderless align-middle mb-0">
                                                <thead class="table-light">
                                                    <tr>
                                                        <th scope="col ms-3">Campaign Name</th>
                                                        <th scope="col ms-3">List</th>
                                                        <th scope="col">Template</th>
                                                        <th scope="col">Recipent</th>
                                                        <th scope="col">Delivered</th>
                                                        <th scope="col">Created Date</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="campain of campainData" :key="campain.ID">
                                                        <td>{{ campain.Title }}</td>
                                                        <td>
                                                            <div class="d-flex align-items-center">
                                                                <div class="ms-3 flex-grow-1">
                                                                    <h5 class="fs-14 mb-0">
                                                                        {{ campain.ContactList.Title }}
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{{ campain.Template.Title }}</td>
                                                        <td>{{ campain.ContactList.Customer.length }}</td>
                                                        <td>{{ campain.Delivered }}</td>
                                                        <td>{{ campain.Created }}</td>
                                                        <td>
                                                            <button @click="editCampain(campain.ID)"
                                                                data-bs-toggle="modal" data-bs-target="#campainModal"
                                                                class="btn btn-success btn-warning btn-sm me-2">Edit</button>
                                                            <button @click="sendCampain(campain.ID)"
                                                                class="btn btn-success btn-info btn-sm" :id="`btnSendCampain-${campain.ID}`">Send
                                                                Campain</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div class="text-center mt-3" style="display: none;">
                                            <a href="javascript:void(0);" class="text-success "><i
                                                    class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i> Load
                                                more </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end tab pane -->
                    <!-- tab list -->
                    <div class="tab-pane fade show active" id="listCampgain" role="tabpanel">
                        <div class="row g-4 mb-3">
                            <div class="col-sm">
                                <div class="d-flex">
                                    <div class="search-box me-2">
                                        <input v-model="searchQuery" type="text" class="form-control" placeholder="Search lists...">
                                        <i class="ri-search-line search-icon"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-auto">
                                <div>
                                    <button type="button" class="btn btn-info" data-bs-toggle="modal"
                                        data-bs-target="#inviteMembersModal"
                                        @click="contactAdd = []; listPreview = []; titleList = ''; showAlert = false; isUpdate = false"><i
                                            class="ri-add-line me-1 align-bottom"></i> Create New Lists</button>
                                </div>
                            </div>
                        </div>
                        <!-- end row -->

                        <div v-if="showShimmer">
                            <div class="team-list list-view-filter" v-for="i in 10" :key="i">
                                <div class="card team-box placeholder-glow">
                                    <div class="card-body px-4 placeholder bg-light" style="border-radius: 5px">
                                        <div class="row align-items-center team-row">
                                            <div class="col team-settings">
                                                <div class="row align-items-center placeholder-glow">
                                                    <div class="col text-end dropdown placeholder bg-warning"
                                                        style="border-radius: 8px">
                                                        <a class="btn btn-warning m-1" href="javascript:void(0);"
                                                            style="height: 30px; width: 60px;">
                                                            <i class="me-2 align-bottom"></i>
                                                        </a>
                                                    </div>
                                                    <div class="col text-end dropdown placeholder bg-danger"
                                                        style="border-radius: 8px">
                                                        <a class="btn btn-danger m-1" href="javascript:void(0);"
                                                            style="height: 30px; width: 60px;">
                                                            <i class="me-2 align-bottom"></i>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col">
                                                <div class="team-profile-img placeholder-glow">
                                                    <div class="team-content" style="width: 130px">
                                                        <a href="#" class="d-block">
                                                            <h5 class="fs-16 mb-1 placeholder bg-dark col-12"></h5>
                                                        </a>
                                                        <p class="text-muted mb-0 placeholder bg-dark col-12">&nbsp;</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col">
                                                <div class="row text-muted text-center placeholder-glow">
                                                    <div class="col-6 border-end border-end-dashed">
                                                        <h5 class="mb-1 placeholder">123</h5><br />
                                                        <p class="text-muted mb-0 placeholder">Contact</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-lg-2 col">
                                                <div class="text-end">
                                                    <router-link to="/pages/profile" class="btn btn-light view-btn">View
                                                        Profile</router-link>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div><!--end card-->
                            </div>
                        </div>

                        <div v-if="!showAlert && listsData.length === 0">
                            <div class="team-list list-view-filter">
                                <div class="card team-box">
                                    <div class="card-body px-4 bg-light" style="border-radius: 5px">
                                        <div class="row align-items-center team-row">
                                            <h3 class="text-center">No Data Found</h3>
                                        </div>
                                    </div>
                                </div><!--end card-->
                            </div>
                        </div>

                        <div class="team-list list-view-filter" v-for="(lists, i) of listsData" :key="i">
                            <div class="card team-box">
                                <div class="card-body px-4">
                                    <div class="row align-items-center team-row">
                                        <div class="col team-settings">
                                            <div class="row align-items-center">
                                                <div class="col text-end dropdown">
                                                    <a class="btn btn-warning m-1" href="javascript:void(0);"
                                                        data-bs-toggle="modal" data-bs-target="#inviteMembersModal"
                                                        @click="edit(lists.ID)">
                                                        <i class="ri-edit-line text-white me-2 align-bottom"></i>Edit
                                                    </a>
                                                    <a class="btn btn-danger m-1" href="javascript:void(0);"
                                                        @click="removeLists(lists.ID)">
                                                        <i
                                                            class="ri-delete-bin-5-fill text-white me-2 align-bottom"></i>Delete
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col">
                                            <div class="team-profile-img">
                                                <div class="team-content">
                                                    <a href="#" class="d-block">
                                                        <h5 class="fs-16 mb-1">{{ lists.Title }}</h5>
                                                    </a>
                                                    <p class="text-muted mb-0">Team Leader & HR</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col">
                                            <div class="row text-muted text-center">
                                                <div class="col-6 border-end border-end-dashed">
                                                    <h5 class="mb-1">{{ lists.Customer.length }}</h5>
                                                    <p class="text-muted mb-0">Contact</p>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-2 col">
                                            <div class="text-end">
                                                <router-link to="/pages/profile" class="btn btn-light view-btn">View
                                                    Profile</router-link>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div><!--end card-->
                        </div>
                        <!-- end team list -->

                        <div class="row g-0 text-center text-sm-start align-items-center mb-3">
                            <div class="col-sm-6">
                                <div>
                                    <p class="mb-sm-0">Showing 1 to 10 of {{ listsData.length }} entries</p>
                                </div>
                            </div> <!-- end col -->
                            <div class="col-sm-6">
                                <!-- <ul
                                    class="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                                    <li class="page-item disabled"> <a href="#" class="page-link"><i
                                                class="mdi mdi-chevron-left"></i></a> </li>
                                    <li class="page-item"> <a href="#" class="page-link">1</a> </li>
                                    <li class="page-item active"> <a href="#" class="page-link">2</a> </li>
                                    <li class="page-item"> <a href="#" class="page-link">3</a> </li>
                                    <li class="page-item"> <a href="#" class="page-link">4</a> </li>
                                    <li class="page-item"> <a href="#" class="page-link">5</a> </li>
                                    <li class="page-item"> <a href="#" class="page-link"><i
                                                class="mdi mdi-chevron-right"></i></a> </li>
                                </ul> -->
                            </div><!-- end col -->
                        </div><!-- end row -->
                    </div>
                    <!-- end tab pane -->
                </div>
            </div>
            <!-- end col -->
        </div>
        <!-- end row -->
    </Layout>

    <!-- Modal add list -->
    <div class="modal fade" id="inviteMembersModal" tabindex="-1" aria-labelledby="inviteMembersModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-0">
                <div class="modal-header p-3 ps-4 bg-soft-success">
                    <h5 class="modal-title" id="inviteMembersModalLabel">Contact</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-4">


                    <!-- Input with Label -->
                    <div class="mb-3">
                        <label for="labelInput" class="form-label">Label Lists :</label>
                        <input v-model="titleList" type="text" class="form-control" id="labelInput"
                            placeholder="Label / Nama">
                    </div>

                    <div class="mb-4 d-flex align-items-center">
                        <div class="me-2">
                            <h5 class="mb-0 fs-13">Contact :</h5>
                        </div>
                        <div v-if="listPreview.length === 0"
                            class="avatar-group justify-content-center placeholder-glow">
                            <a href="javascript: void(0);" v-for="i in 4" :key="i"
                                class="avatar-group-item placeholder">
                                <div class="avatar-xs">
                                    <img :src="profile" alt="" class="rounded-circle img-fluid">
                                </div>
                            </a>
                        </div>
                        <div v-else class="avatar-group justify-content-center">
                            <a href="javascript: void(0);" v-for="data of listPreview.slice(0, 10)" :key="data.id"
                                class="avatar-group-item">
                                <div class="avatar-xs">
                                    <img :src="data.photo" alt="" class="rounded-circle img-fluid" data-bs-toggle="tooltip" data-bs-placement="right" :title="data.nama">
                                </div>
                            </a>
                            <a href="javascript: void(0);" class="avatar-group-item" v-if="this.listPreview.length > 10" data-bs-toggle="tooltip" data-bs-placement="right" :title="`${this.listPreview.length - 10} contact lainnya`">
                                <div class="avatar-xs">
                                    <!-- <img :src="data.photo" alt="" class="rounded-circle img-fluid"> -->
                                    <div class="avatar-title rounded-circle bg-info userprofile">
                                        {{ this.listPreview.length - 10 }}
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>

                    <div class="mx-n4 px-4" data-simplebar="init" style="max-height: 225px;">
                        <div class="simplebar-wrapper" style="margin: 0px -24px;">
                            <div class="simplebar-height-auto-observer-wrapper">
                                <div class="simplebar-height-auto-observer">

                                </div>
                            </div>
                            <div class="simplebar-mask">
                                <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
                                    <div ref="contentScroll" class="simplebar-content-wrapper" tabindex="0" role="region"
                                        aria-label="scrollable content" style="height: auto; overflow: hidden scroll;">
                                        <div class="simplebar-content" style="padding: 0px 24px;">
                                            <div class="vstack gap-3">
                                                <div v-for="contact of this.listCustomer" :key="contact.id">
                                                    <div class="d-flex align-items-center">
                                                        <div class="avatar-xs flex-shrink-0 me-3">
                                                            <img :src="contact.ava" alt=""
                                                                class="img-fluid rounded-circle">
                                                        </div>
                                                        <div class="flex-grow-1">
                                                            <h5 class="fs-13 mb-0"><a href="#"
                                                                    class="text-body d-block">{{ contact.name }}</a>
                                                            </h5>
                                                        </div>
                                                        <div class="flex-shrink-0">
                                                            <div
                                                                class="form-check form-check-outline form-check-info mb-3">
                                                                <input v-model="contactAdd" :value="contact.id"
                                                                    class="form-check-input"
                                                                    @change="addToList(contact)" type="checkbox"
                                                                    id="formCheck18">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- end list -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="simplebar-placeholder" style="width: auto; height: 272px;">
                            </div>
                        </div>
                        <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
                            <div class="simplebar-scrollbar" style="width: 0px; display: none;">
                            </div>
                        </div>
                        <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
                            <div class="simplebar-scrollbar"
                                style="height: 186px; display: block; transform: translate3d(0px, 0px, 0px);">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light w-xs" data-bs-dismiss="modal"
                        id="closeModal">Cancel</button>
                    <button type="button" class="btn w-xs"
                        :class="[{ 'btn-success': isUpdate === false, 'btn-warning': isUpdate === true }]"
                        @click="saveLists">Save</button>
                </div>
            </div>
            <!-- end modal-content -->
        </div>
        <!-- modal-dialog -->
    </div>
    <!-- end -->

    <!-- Modal add templates -->
    <div class="modal fade" id="addTemplates" tabindex="-1" aria-labelledby="inviteMembersModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-fullscreen">
            <div class="modal-content border-0">
                <div class="modal-header p-3 ps-4 bg-soft-success">
                    <h5 class="modal-title" id="inviteMembersModalLabel">Templates</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-4">
                    <div class="row">
                        <div class="col-8">
                            <div class="card">
                                <div class="card-header align-items-center">
                                    <h4 class="card-title mb-0">Custom Template</h4>
                                </div>
                                <div class="card-body">
                                    <div class="mb-3">
                                        <input v-model="titleTemplate" class="form-control" type="text"
                                            placeholder="Title Template">
                                    </div>
                                    <div class="form-check mb-3">
                                        <input v-model="isMedia" @change="mediaRemove($event)" class="form-check-input"
                                            type="checkbox" id="formCheck6" checked>
                                        <label class="form-check-label" for="formCheck6">
                                            With media
                                        </label>
                                    </div>
                                    <!-- File Input Sizing Default -->
                                    <div class="mb-3" v-if="isMedia">
                                        <input class="form-control" ref="file" @change="uploadMedia" id="formFileSm"
                                            type="file">
                                    </div>
                                    <QuillEditor v-model:content="content" @update:content="loadHtml(content)"
                                        theme="snow" :toolbar="['bold', 'italic', 'strike']" contentType="html"
                                        placeholder="Input here" ref="quilEditor"></QuillEditor>
                                    <button type="button" @click="addVariable()"
                                        class="btn btn-success btn-label btn-sm mt-2 waves-effect waves-light">
                                        <i class="ri-add-fill label-icon align-middle fs-16 me-2"></i> Add Variable
                                    </button>
                                    <p class="mt-2">List Variable : </p>
                                    <div class="row" v-for="(data, i) of listVariable.length" :key="i">
                                        <div :id="`rowVar_${i}`" class="col-11">
                                            <select @change="changeVar(i, data)"
                                                :id="`var_id_${i}`" class="form-select mb-2 col-3">
                                                <option selected value="">Select your Variable</option>
                                                <option class="optionSelectVar" value="name">Nama</option>
                                                <option class="optionSelectVar" value="tgl">Tanggal</option>
                                            </select>
                                        </div>
                                        <div :id="`rowDate_${i}`" style="display: none;" class="col-5">
                                            <input type="date" :id="`datePick_${i}`" @change="changeVar(i)" class="form-control mb-2" />
                                        </div>
                                        <div :id="`action_${i}`" v-if="i > 0" class="col-1">
                                            <button :id="`btnRemove_${i}`" type="button" @click="removeVar(i)" class="btn btn-danger btn-icon waves-effect waves-light">
                                                <i class="ri-delete-bin-5-line"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="card h-100">
                                <div class="card-header align-items-center">
                                    <h4 class="card-title mb-0">Preview</h4>
                                </div>
                                <div class="card-body" id="previewBody">

                                    <div class="speech-wrapper">
                                        <div class="bubble">
                                            <div class="txt">
                                                <p class="name">Example</p>
                                                <div id="templateBC">
                                                    <img v-if="isMedia" :src="prevImg"
                                                        style="max-width: 213px; max-height: fit-content;" />
                                                    <!-- <div v-html="dataContent" class="mt-2 message"></div> -->
                                                    <div v-html="dataContent" class="message mt-2"></div>
                                                </div>
                                                <span class="timestamp">{{ this.timestamp }}</span>
                                            </div>
                                            <div class="bubble-arrow"></div>
                                        </div>
                                    </div>

                                    <!-- <div id="templateBC" style="max-width: 200px;">
                                        <img v-if="isMedia" :src="prevImg"
                                            style="max-width: 200px; max-height: fit-content;" />
                                        <div v-html="dataContent" class="mt-2"></div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light w-xs" data-bs-dismiss="modal"
                        id="closeModalAddTemplate">Cancel</button>
                    <button type="button" class="btn w-xs"
                        :class="[{ 'btn-success': isUpdate === false, 'btn-warning': isUpdate === true }]"
                        @click="saveTemplate">Save</button>
                </div>
            </div>
            <!-- end modal-content -->
        </div>
        <!-- modal-dialog -->
    </div>
    <!-- end -->

    <!-- Modal campain -->
    <div class="modal fade" id="campainModal" tabindex="-1" aria-labelledby="campainModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content border-0">
                <div class="modal-header p-3 ps-4 bg-soft-success">
                    <h5 class="modal-title" id="inviteMembersModalLabel">Broadcast</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-4">

                    <!-- Input with Label -->
                    <div class="mb-3">
                        <label for="labelInput" class="form-label">Campaign Name :</label>
                        <input v-model="TitleCampaign" class="form-control" type="text"
                            placeholder="Campaign Name">
                    </div>

                    <div class="mb-3">
                        <label for="labelInput" class="form-label">Label Lists :</label>
                        <select v-model="idList" class="form-select mb-2 col-6">
                            <option selected value="">Select your List</option>
                            <option v-for="(list, i) of listsData" :key="i" :value="list.ID">{{
                        list.Title + "("+list.Customer.length+" Customer)" }}</option>
                        </select>
                    </div>

                    <div class="mb-3">
                        <label for="labelInput" class="form-label">Template Broadcast :</label>
                        <select v-model="idTemplate" class="form-select mb-2 col-6">
                            <option selected value="">Select your Template</option>
                            <option v-for="template of templateData" :key="template.ID" :value="template.ID">{{
                                template.Title
                            }}</option>
                        </select>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light w-xs" data-bs-dismiss="modal"
                        id="closeModalAddCampain">Cancel</button>
                    <button type="button" class="btn w-xs"
                        :class="[{ 'btn-success': isUpdate === false, 'btn-warning': isUpdate === true }]"
                        @click="sendBC">Save</button>
                </div>
            </div>
            <!-- end modal-content -->
        </div>
        <!-- modal-dialog -->
    </div>
    <!-- end -->

</template>